<template>
  <div class="page">
    <el-container>
      <el-aside class="nav" width="200px">
        <div class="header">
          <div class="logo">
            <img width="34" height="34" src="@/assets/logo.png" alt=""/>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="desc">
            <span>收藏中心</span>
          </div>
        </div>
        <div class="menu">
          <div class="item" :class="crouter==='/favourCenter/productFavour'?'active':''" @click="modulechange('/favourCenter/productFavour')">
            <span>商品收藏</span>
          </div>
          <div class="item" :class="crouter==='/favourCenter/keywordFavour'?'active':''" @click="modulechange('/favourCenter/keywordFavour')">
            <span>关键词收藏</span>
          </div>
        </div>
      </el-aside>
      <el-container>
        <el-main class="main">
          <router-view/>
        </el-main>
        <el-footer class="footer">
          <div class="item">
            <span>使用条款</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>隐私政策</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>联系客服</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>©来搜</span>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      crouter: '',
    }
  },
  mounted() {
    this.crouter = this.$route.path
    console.log(this.crouter)
  },
  methods: {
    modulechange(p) {
      this.crouter = p
      this.$router.push(p)
    },
  }
}
</script>

<style scoped>
.page {
  overflow: hidden;
}

.nav {
  user-select: none;
  height: 100vh;
  overflow: hidden;
}

.header {
  height: 70px;
  display: flex;
  align-items: center;
}

.header .logo {
  padding: 0 20px;
}

.header .vcut {
  height: 24px;
  margin: 0;
}

.header .desc {
  margin-left: 20px;
  font-size: 14px;
}

.menu .item {
  font-size: 13px;
  color: #515151;
  display: flex;
  height: 46px;
  align-items: center;
  padding-left: 30px;
}

.menu .item:hover {
  cursor: pointer;
  background-color: #eee;
}

.menu .item:hover span {
  background: rgba(0, 0, 0, 0);
}

.menu .item.active {
  color: #FF6A00;
}

.main {
  height: calc(100vh - 60px);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  min-width: 450px;
  overflow: hidden;
}

.footer .item {
  padding: 0 10px;
  color: #999;
}

.footer .item:hover {
  cursor: pointer;
}
</style>